@import '~styles/abstracts/abstracts';

.form {
  &__label {
    display: block;
    font-size: 12px;
    line-height: 14px;
    text-align: left;

    @extend %form__label;

    &.inline-block {
      display: inline-block;
    }
  }

  &__row,
  &__flex {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: $base * 5;
    }

    > div {
      position: relative;

      &:not(:last-of-type) {
        margin-right: $base * 3;
      }
    }
  }
}
