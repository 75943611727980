@import '~styles/abstracts/variables';
@import '~styles/abstracts/colours';

.ql-container {
  margin-bottom: $gutter/2;

  .ql-editor {
    border-bottom: 1px solid $border--inactive;
    font-size: 14px;
    // max-height: 133px;
    overflow: auto;
    padding: 0;
    padding-top: $base;
    padding-bottom: $base;

    &.ql-blank::before {
      color: $font--light;
      font-style: normal;
      left: 0;
      right: 0;
      z-index: 0;
    }

    &:focus {
      outline: none;
      border-bottom-color: $border--dark;
    }
  }

  .ql-tooltip {
    z-index: 1;
  }
}
