@import '~styles/abstracts/colours';
@import '~styles/abstracts/variables';

.editor {
  font-weight: 200;
  position: relative;

  &:hover {
    .DraftEditor-editorContainer {
      color: $font--normal;
    }
  }

  &.form__row--has-focus .form__label {
    &::before {
      background: white;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__save,
  &__saving,
  &__saved {
    align-items: center;
    background: rgba($brand--primary, 1);
    border-radius: 100px;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.6px;
    margin: $base/2;
    max-width: 80px; // required to animate the width...
    padding: ($base/2) $gutter;
    text-transform: uppercase;
    transition: 0.2s all ease;

    &--disabled {
      background-color: $background--inactive;
      pointer-events: none;
    }

    &:hover {
      background: $brand--primary;
    }

    .p-progress-spinner-circle {
      animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color-white 6s ease-in-out infinite;
    }

    @keyframes p-progress-spinner-color-white {
      100%,
      0% {
        stroke: #fff;
      }
    }
  }

  &__input {
    li {
      margin-left: $gutter;
      margin-bottom: $gutter / 2;
    }
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }

  .public-DraftEditorPlaceholder-root {
    transition: 0.3s all ease;
    font-size: 14px;
    color: $font--light;
  }

  .public-DraftEditorPlaceholder-hasFocus {
    color: rgba($font--deep-blue, 0.4);
  }

  .DraftEditor-editorContainer {
    color: $font--dark;
    opacity: 1;
    transition: 0.2s all ease;
  }

  .editor__toolbar .rdw-inline-wrapper,
  .editor__toolbar .rdw-list-wrapper {
    border: none;
    border-radius: 100px;
    left: -4px;
    margin: 0;
    padding: 0 $base;
    padding: 0;
    top: -10px;
  }

  .editor__toolbar {
    .rdw-list-wrapper:last-child {
      .rdw-option-wrapper {
        &:last-child {
          border-radius: 0 100px 100px 0;
        }
      }
    }

    .rdw-inline-wrapper {
      .rdw-option-wrapper {
        &:first-child {
          border-radius: 100px 0 0 100px;
        }
      }
    }

    .rdw-inline-wrapper:last-child {
      .rdw-option-wrapper {
        &:last-child {
          border-radius: 0 100px 100px 0;
        }
      }
    }

    div {
      .rdw-option-wrapper {
        $size: 32px;

        border: none;
        border-radius: 0;
        height: $size;
        margin: 0;
        transition: 0.3s all ease;
        width: $size + $base;

        &:hover {
          background: $background--inactive;
          box-shadow: none;
          color: $brand--primary;
        }
      }
    }
  }

  .rdw-editor-main {
    overflow-x: hidden;
  }

  .rdw-inline-wrapper {
    border-radius: 100px;
    margin-bottom: 0;
  }

  .rdw-block-wrapper {
    margin-bottom: 0;
    margin-top: 0;
  }

  .rdw-dropdown-wrapper {
    border-bottom: 0;
    border-top: 0;

    &:hover {
      box-shadow: none;
    }
  }

  .editor__toolbar {
    border: none;
    border-radius: 100px;
    box-shadow: 0 0 30px 0 rgba(24, 51, 72, 0.1);
    left: -5px;
    padding: 0;
    position: absolute;
    top: -10px;
    transform: translateY(-100%);
    transition: 0.3s all ease;
    z-index: 100;
  }
}
