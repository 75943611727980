@import '~styles/abstracts/abstracts';

.page-header {
  display: flex;
  padding-bottom: $base * 5;
  padding-top: $spacer;

  &__filter {
    flex: 1 0 auto;
  }

  &__actions {
    flex: 0 0 auto;
  }

  > * {
    &:not(:last-child) {
      margin-right: $gutter * 2;
    }
  }

  .main.fixed-header &--sticky,
  &--sticky {
    background: $background;
    flex: 0 0 auto;
    margin-bottom: $base * 5;
    max-width: $container-width + 18px;
    padding-bottom: 0;
    padding-left: 9px;
    padding-right: 9px;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 10;

    // &:before {
    // 	position: absolute;
    // 	content: '';
    // 	top: 0;
    // 	left: 50%;
    // 	transform: translateX(-50%) translateY(-100%);
    // 	width: 120%;
    // 	height: 48px;
    // 	background: #F7F8F9;
    // 	z-index: -1;
    // }

    &::after {
      background-image: linear-gradient(#f7f8f9 50%, #f7f8f900 100%);
      bottom: 0;
      content: '';
      height: $base * 6;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) translateY(100%);
      width: 120%;
      z-index: -1;
    }
  }
}

.full-header {
  background: white;
  margin-bottom: $base * 8;
  width: 100%;
  width: 100%;

  &--fixed {
    position: relative;
    position: sticky;
    top: 0;
    z-index: 4;

    &::after {
      background-image: linear-gradient(#f7f8f9 50%, #f7f8f900 100%);
      bottom: 0;
      content: '';
      height: $base * 8;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) translateY(100%);
      width: 120%;
      z-index: -1;
    }
  }
}
