@import '~styles/abstracts/variables';
@import '~styles/abstracts/colours';

.avatar {
  border-radius: 100px;
  display: inline-block;
  flex: 0 0 28px;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    height: 100%;
    width: 100%;
  }

  &--missing-email:not(:last-child) {
    margin-right: 0;
  }

  &__missing-email {
    background: #fff;
    border-radius: 50%;
    color: $brand--negative;
    height: 16px;
    left: -10px;
    position: relative;
    top: -20px;
    width: 16px;
    z-index: 2;
  }
}
