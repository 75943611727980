@import '~styles/abstracts/variables';

.fancy-dropdown--heading.fancy-dropdown {
  .fancy-dropdown {
    &__content {
      padding: $base * 4;
      width: 240px;
    }

    &__heading {
      transition: 0.5s all $bezier;
    }

    &__item {
      font-size: 18px;
      font-weight: 100;
      opacity: 0;
      transform: translateY(-24px);
      transition: 0.4s all ease;
      transition-delay: transform 0.4s, opacity 0.5s;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &.is--open {
    .fancy-dropdown {
      &__heading {
        transform: translateY(8px);
      }

      &__item {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
