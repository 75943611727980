@import '~styles/abstracts/abstracts';

// .header {
//   transform: translate(0);
//   z-index: 1000;
// }

.nav {
  align-items: center;
  background: white;
  border-bottom: 1px solid $border--lightest;
  display: flex;
  font-size: 14px;
  height: 64px;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 $spacer;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &__toggle {
    display: none;
  }

  &__logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__env-text {
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 17px;
    box-shadow: 0 8px 16px -8px rgba(42, 83, 115, 0.14);
  }

  &__version-text {
    background-color: #fb541c;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 17px;
    box-shadow: 0 8px 16px -8px rgba(42, 83, 115, 0.14);
  }

  &__logo {
    flex: 0 0 42px;

    img {
      width: 100%;
    }
  }

  &__profile {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 16px;
    transition: 0.2s all ease;

    &:hover {
      background: $border--lightest;
    }

    &-avatar,
    &-name {
      margin-right: $gutter / 2;
    }

    &-button {
      align-items: center;
      cursor: pointer;
      display: flex;
    }

    &-link {
      color: $font--dark;
      display: block;
      padding: $gutter/4 $gutter/2;

      &:hover {
        color: $font--dark;
        text-decoration: none;
      }
    }
  }

  &__list {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &__link {
    color: $font--light;
    display: inline-block;
    opacity: 0.6;
    text-align: center;
    text-decoration: none;
    transition: 0.2s all ease;
    padding: 24px;
    border-bottom: 0px solid transparent;

    &:hover {
      opacity: 1;
      text-decoration: none;
      color: $font--dark;
    }

    &--active {
      color: $brand--primary;
      opacity: 1;
      border-bottom: 2px solid $brand--primary;
      padding-bottom: 19px;
      padding-top: 21px;

      &:hover {
        color: $brand--primary;
      }
    }
  }
}
