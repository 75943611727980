@import './reset';
@import '~styles/abstracts/variables';
@import './fonts';
@import './helpers';
@import './typography';
@import '~styles/abstracts/placeholders';
@import '~styles/abstracts/colours';
@import '~styles/abstracts/blueprint';

body {
  background: $background;
  color: $font--dark;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
  min-height: 100vh;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex: 1 0 auto;

  .app__wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
}

div {
  line-height: 1.414;
}

.transition-wrapper {
  display: flex;
  flex: 1 0 auto;
}

.main {
  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  margin-top: $nav-height;
  max-height: calc(100vh - 64px);
  max-width: none;
  opacity: 1;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  transition: transform 0.5s, opacity 0.5s;
  // padding-top: $nav-height;

  &.fixed-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: none;
    min-height: calc(100vh - 64px);

    .page-header {
      max-width: $container-width;
      width: 100%;
    }

    .roles-list {
      flex: 1 0 auto;
      width: 100%;
    }
  }
}

.wrapper {
  max-width: $container-width;
}

.container {
  @extend %container;
}
