// -------------------------------------------------------------
//
//	Typographic styles
//
//	------------------------------------------------------------

@import '~styles/abstracts/colours';
@import '~styles/abstracts/variables';

$font-heading: 'Open Sans', sans-serif;
$font-copy: 'Open Sans', sans-serif;

%small-label {
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
}

%form__label {
  color: $font--dark;
  font-weight: bold;
  letter-spacing: 0.01rem;
}

%form__field {
  display: block;
  font-family: $font-copy;
  font-size: 14px;
  height: auto;
  line-height: 1;
  padding: $base $gutter;
}

%large-text {
  font-size: 40px;
  font-weight: lighter;
}

%link {
  color: $brand--primary;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  position: relative;
  text-transform: none;

  &:hover::after {
    transform: scale(1, 1) translateY(2px);
    transform-origin: left center;
  }

  &::after {
    background: $brand--primary;
    bottom: 2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transform: scale(0, 1) translateY(2px);
    transform-origin: right center;
    transition: transform 0.2s ease;
    width: 100%;
  }

  &--dark {
    color: $font--dark;

    &::after {
      background: $font--dark;
    }

    &:hover {
      color: $font--dark;
    }
  }
}

%modal-form__title {
  color: $brand--primary;
  font-size: 12px;
  font-weight: bold;
  padding-top: $big-spacer;
  text-transform: uppercase;
}

%modal-form__heading {
  color: $font--dark;
  font-size: 24px;
}

%modal-form__client-name {
  color: $font--deep-blue;
  font-size: 16px;
  font-weight: lighter;
}

%modal-form__role-name {
  color: $font--deep-blue;
  font-size: 24px;
  font-weight: bold;
}
