.ReactModal {
  &__Overlay {    
    height: 100vh;
    width: 100vw;
  }

  &__Body--open {
    overflow: hidden;
  }

  &__Scroll {
    background: white;
    bottom: 0;
    height: 100%;
    left: 0;
    max-height: 100vh;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  &__Content {
    background: white;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    max-height: 100vh;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  &__Close {
    color: $font--light;
    line-height: 1;
    position: absolute;
    right: $spacer;
    text-align: center;
    top: $spacer;

    svg {
      display: block;
    }
  }

  &__Back {
    color: $font--light;
    left: $spacer;
    line-height: 1;
    position: absolute;
    text-align: center;
    top: $spacer;
  }
}
