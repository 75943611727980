// Create triangle
@mixin triangle($direction, $size: 8px, $color: #222) {
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;

  @if ($direction == 'up') {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: none;
  }

  @else if ($direction == 'down') {
    border-top: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: none;
  }

  @else if ($direction == 'left') {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-left: none;
  }

  @else if ($direction == 'right') {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-right: none;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
