@import '~styles/abstracts/colours';

.error {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  &__title {
    color: $brand--primary;
    font-weight: 700;
    font-size: 24px;
  }

  &__message {
    font-size: 18px;
  }

  &__container--fullscreen {
    min-height: 100vh;
  }

  &__container--fullscreen &__title {
    font-size: 42px;
  }

  &__container--fullscreen &__message {
    font-size: 24px;
  }
}
