@import '~styles/abstracts/abstracts';

.chip,
.chip-split {
  align-items: center;
  background: #6a7c97;
  border-radius: 100px;
  color: white;
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  padding: 4px $base;
  position: relative;
  vertical-align: middle;
  white-space: pre;

  &:not(:last-child) {
    margin-right: $base;
  }

  &.is--large {
    padding: $base $base * 2;

    &.icon--both,
    &.icon--left {
      svg:first-child {
        margin-left: -($gutter * 0.33);
        margin-right: $gutter * 0.66;
      }
    }
  }

  &.icon--both,
  &.icon--left {
    svg:first-child {
      flex: 0 0 auto;
      margin-right: 4px;
    }
  }

  &.icon--both,
  &.icon--right {
    svg:last-child {
      flex: 0 0 auto;
      margin-left: 4px;
    }
  }

  &--alert,
  &--warning {
    background: #f64662;

    &.inverse {
      background: transparent;
      border: 1px solid #f64662;
      color: #f64662;
    }
  }

  &--caution {
    background: #fccc58;
    border: 1px solid #fccc58;

    &.inverse {
      background: transparent;
      color: #fccc58;
    }
  }

  &--primary {
    background: $brand--primary;
    border: 1px solid $brand--primary;

    &.inverse {
      background: transparent;
      color: $brand--primary;
    }
  }

  &--neutral {
    background: #6a7c97;
    border: 1px solid #6a7c97;

    &.inverse {
      background: transparent;
      color: #6a7c97;
    }
  }

  &--positive {
    background: #008af3;
    border: 1px solid #008af3;

    &.inverse {
      background: transparent;
      color: #008af3;
    }
  }

  &--linkedin {
    background: #0072b1;
    border: 1px solid #0072b1;

    &.inverse {
      background: transparent;
      color: #0072b1;
    }
  }

  &--affirmative {
    background: #50c736;
    border: 1px solid #50c736;

    &.inverse {
      background: transparent;
      color: #50c736;
    }
  }

  &--passive {
    background: $border--inactive;
    color: $font--deep-blue;
    font-weight: normal;
  }

  &--ghost {
    background: transparent;
    border: 1px solid #e4e7ec;
    color: rgba(#6a7c97, 0.5);
    font-weight: lighter;

    strong {
      color: #6a7c97;
    }
  }

  &--strong {
    font-weight: bold;
  }

  &--right {
    margin-left: auto;
  }
}

.chip-split {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  line-height: 1.2em;

  &:not(:last-child) {
    margin-right: 0;
  }

  & + .chip-split {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 100px;
  }
}
