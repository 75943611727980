.uploader {
  &:hover {
    .uploader__icon {
      transform: scale(1.2) translateX(-50%) translateY(-8px);
    }

    img[alt]::before {
      transform: scale(1.2) translateY(-16px);
    }

    .uploader__heading,
    .uploader__subheading {
      color: $font--light;
      opacity: 0.6;
      transform: translateY(-12px);
    }

    // .fine-uploader-dropzone-container {
    //   border-color: $brand--primary;
    // }
  }

  .fine-uploader-dropzone-container {
    &.form__upload-field--active {
      .uploader__icon {
        transform: scale(1.2) translateX(-50%) translateY(-8px);
      }

      .uploader__heading,
      .uploader__subheading {
        transform: translateY(-12px);
      }
    }
  }

  .form__upload-field {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding-bottom: $spacer * 2;
    text-align: center;
    width: 100%;
  }

  &__icon {
    font-size: 40px;
    left: 50%;
    position: absolute;
    top: $gutter * 6;
    transform: translateX(-50%);
    transform-origin: center;
    transition: 0.2s all ease;
  }

  .react-fine-uploader-file-input-container {
    width: 100%;
  }

  .react-fine-uploader-total-progress-bar-container {
    border-radius: $border-radius;
    border: 1px solid $brand--primary;
    background-clip: padding-box;
    overflow: hidden;
    height: $base;
    width: 100%;
  }

  .react-fine-uploader-total-progress-bar {
    background: $brand--primary;
    height: $base;
    transition: width 0.3s ease-in-out;
  }

  &__heading {
    font-size: $base * 4;
    font-weight: bold;
    margin-bottom: $base;
    margin-top: $spacer;
    transition: 0.2s all ease;
  }

  &__subheading {
    color: $font--light;
    font-size: 14px;
    font-weight: lighter;
    transition: 0.2s all ease;
  }

  &__underline {
    position: relative;

    &::after {
      background: $border--inactive;
      bottom: -2px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &__field {
    input {
      cursor: pointer;
    }
  }

  .form__finalise-changes {
    @extend %link;

    bottom: 60px;
    left: 50%;
    position: absolute !important; /* ugh */
    transform: translateX(-50%);
    z-index: 2;
  }

  img {
    &[alt] {
      &::after {
        background-color: #fff;
        content: '';
        display: block;
        font-family: 'Helvetica';
        font-weight: 300;
        height: 100%;
        left: 0;
        line-height: 2;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
      }

      &::before {
        content: attr(alt);
        display: block;
        font-size: 24px;
        padding-top: 90px;
        position: relative;
        transition: 0.2s all ease;
        z-index: 999;
      }
    }
  }
}
