@import '~styles/abstracts/colours';

.largeInput{
    border: none;
    box-shadow: none;
    color: $font--deep-blue;
    font-size: 28px;
    font-weight: 200;
    letter-spacing: 2px;
    line-height: 1;
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.inputBox{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top: $brand--primary;
}

.inputClass{     
    font-family: 'Open Sans', sans-serif;
    border-radius: 5px;
    border: 1px solid $border--inactive;
    box-shadow: none;
    color: $font--deep-blue;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 1;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 300px;
}

.popover-container {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-left: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 15px;
    font-weight: 400;
}

/* Hide the browser's default checkbox */
.popover-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    
}

/* Create a custom checkbox */
.checkmark {
    width: 15px;
    height: 15px;
}

/* When the checkbox is checked, add a blue background */
.popover-container input:checked ~ .checkmark { color: #2196F3; }

// label > input:checked {
//   color: #33f321 !important;
//   font-size: 123px;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    display: none;
}

/* Show the checkmark when checked */
.popover-container input:checked ~ .checkmark:after { display: block; }

/* Style the checkmark/indicator */
.popover-container .checkmark:after {
    margin-left: 10px;
    margin-bottom: 2px;
    width: 7px;
    height: 13px;
    border: solid #2196F3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}