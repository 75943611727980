@import '~styles/abstracts/variables';
@import '~styles/abstracts/material-icons';

%container {
  margin: 0 auto;
  max-width: $container-width;
  position: relative;
  // padding: 0 $gutter*2;
  width: 100%;
}

%search-box__input {
  background: 0 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #1f2532;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: lighter;
  height: 42px;
  line-height: 1;
  margin-bottom: 0;
  outline: 0;
  padding: 0 18px 0 48px;
  transition: width 0.3s ease, border-color 0.3s ease;
  width: 100%;

  &::placeholder {
    font-weight: lighter;
    opacity: 0.4;
  }
}

%search-box__item {
  align-items: center;
  color: $font--light;
  cursor: pointer;
  display: flex;
  font-weight: lighter;
  text-align: left;
  padding: 6px $gutter;
  position: relative;
  transform-origin: top;
  transition: 0.5s all ease;

  &.selected {
    color: $brand--blue;

    &::before {
      @extend %material-icons;

      align-items: center;
      content: 'check';
      display: flex;
      height: 18px;
      justify-content: center;
      margin-right: $gutter/2;
      width: 18px;
      font-size: 18px !important;
    }
  }

  &:hover {
    background: $border--lightest;
    color: $font--dark;
  }
}

%search-box__wrapper {
  position: static;

  > .search-box {
    position: absolute;
    right: $gutter * 2;
    top: 0;

    .search-box__input {
      height: 52px;
      line-height: 1;
    }

    .search-box__icon {
      transform: translateY(calc(-50% + 2px));
    }

    .search-box__label {
      color: $font--light;
      cursor: pointer;
      font-size: 14px;
      font-weight: 200;
      line-height: 52px;
      opacity: 1;
      position: absolute;
      right: 54px;
      text-align: right;
      transition: opacity 0.2s ease 0.3s, color 0.3s ease;
      width: max-content;
    }

    &--active .search-box__label {
      opacity: 0;
      transition-delay: 0s;
    }

    &:hover .search-box__label {
      color: $font--deep-blue;
    }
  }
}
