@import '~styles/abstracts/abstracts';

.geosuggest {
  outline: none;
  text-align: left;
  width: 100%;

  input.geosuggest__input.form__input,
  input.geosuggest__input.bp3-input {
    @extend %search-box__input;

    padding-left: 0;
    padding-right: 0;

    &:focus {
    }
  }

  &__suggests-wrapper {
    background: #fff;
    left: 0;
    list-style: none;
    margin-top: -1px;
    max-height: 25em;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    transition: max-height 0.2s, border 0.2s;
    z-index: 5;
  }

  &__suggests {
    margin-top: $gutter/2;
  }

  &__item {
    color: $font--normal;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.4;
    padding: $gutter/2 $gutter/2;
  }
}

.geosuggest__item:focus,
.geosuggest__item:hover {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:focus,
.geosuggest__item--active:hover {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
