$spacer-values: 0, 2, 4, 8, 12, 16, 24, 32, 40, 48, 60, 80, 100;

@each $spacer-values in $spacer-values {
  .mt--#{$spacer-values} {
    margin-top: #{$spacer-values}px;
  }

  .mr--#{$spacer-values} {
    margin-right: #{$spacer-values}px;
  }

  .mb--#{$spacer-values} {
    margin-bottom: #{$spacer-values}px;
  }

  .ml--#{$spacer-values} {
    margin-left: #{$spacer-values}px;
  }

  .pt--#{$spacer-values} {
    padding-top: #{$spacer-values}px;
  }

  .pr--#{$spacer-values} {
    padding-right: #{$spacer-values}px;
  }

  .pb--#{$spacer-values} {
    padding-bottom: #{$spacer-values}px;
  }

  .pl--#{$spacer-values} {
    padding-left: #{$spacer-values}px;
  }
}

.align--right {
  margin-left: auto;
}

.align--center {
  margin-left: auto;
  margin-right: auto;
}

.text--center {
  text-align: center;
}

.hide {
  display: none !important;
}

.relative {
  position: relative;
}
