@keyframes checkboxIn {
  0% {
    transform: scale(0) translateY(-50%);
  }

  50% {
    transform: scale(1.2) translateY(-50%);
  }

  100% {
    transform: scale(1) translateY(-50%);
  }
}

.checkbox {
  position: relative;

  &__input {
    display: none;

    &:checked {
      + label {
        @extend .btn__tinted--positive;

        .checkbox__fill {
          animation: checkboxIn 0.5s ease;
          color: $brand--primary;
          opacity: 1;
        }
      }
    }
  }

  &__label {
    @extend .btn;
    @extend .btn__tinted--passive;

    &:hover {
      background: rgba($brand--primary, 0.2);
      border-color: rgba($brand--primary, 0.7);
      color: $brand--primary;

      svg {
        color: $brand--primary;
      }
    }
  }

  &__outline,
  &__fill {
    color: $font--dark;
    font-size: 18px;
    left: $gutter;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s ease;
  }

  &__outline {
    opacity: 1;
  }

  &__fill {
    opacity: 0;
    transform: scale(0) translateY(-50%);
  }
}
