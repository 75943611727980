// -------------------------------------------------------------
//
//	Button styles
//
//	------------------------------------------------------------
//

/*

Cheatsheet

length($list) : Returns the length of a list.
nth($list, $n) : Returns a specific item in a list at the nth value.
set-nth($list, $n, $value) : Replaces the nth item in a list.
join($list1, $list2) : Joins together two lists into one.
append($list1, $val) : Appends a value onto the end of a list.
zip($lists…) : Combines lists into a single multidimensional list.
index($list, $value) : Returns the position of a value within a list. (You can also use this to determine if a value exists within a list)
list-separator(#list) : Returns the separator of a list.

map-get($map, $key) : Returns the value in a map associated with a given key.
map-merge($map1, $map2) : Merges two maps together into a new map.
map-remove($map, $keys…) : Returns a new map with keys removed.
map-keys($map) : Returns a list of all keys in a map.
map-values($map) : Returns a list of all values in a map.
map-has-key($map, $key) : Returns whether a map has a value associated with a given key.
keywords($args) : Returns the keywords passed to a function that takes variable arguments.
*/

@import '~styles/abstracts/abstracts';

$btn-colours: (
  brand: $brand--primary,
  positive: $brand--primary,
  negative: $brand--negative,
  secondary: $brand--blue,
  neutral: $brand--neutral,
  passive: #aeb5c3,
  highlight: #fff,
);

$startPositive: $brand--secondary;
$endPositive: $brand--primary;

button {
  background: none;
}

.btn {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 38px;
  padding: 0 $base * 2;
  position: relative;
  transition: 0.25s all ease;

  &.read-only {
    cursor: initial;
  }

  &:not(:last-child) {
    margin-right: $base * 2;
  }

  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none !important;

    &:not(.read-only):hover {
      box-shadow: none;
      transform: none;
    }
  }
}

@each $theme, $colour in $btn-colours {
  .btn {
    &__tinted--#{$theme} {
      background: rgba($colour, 0.2);
      border: 1px solid rgba($colour, 0.8);
      color: $colour;

      @if $theme == 'passive' {
        background: rgba($brand--neutral, 0.1);
        color: $brand--neutral;
        border-color: rgba($brand--neutral, 0.3);
      }

      &:not(.read-only):hover {
        background: rgba($colour, 0.3);
        border-color: rgba($colour, 1);
      }
    }
    &__ghost--#{$theme} {
      background: transparent;
      border: 1px solid transparent;
      color: $colour;

      &:not(.read-only):hover {
        background: $colour;
        color: white;

        @if $theme == 'highlight' {
          color: $brand--neutral;
        }
      }

      &:disabled,
      &.disabled {
        background: transparent;
        color: rgba($colour, 0.4);

        @if $theme == 'passive' {
          color: rgba($brand--neutral, 0.4);
        }
      }
    }
    &__ghost-border--#{$theme} {
      background: transparent;
      border: 1px solid $colour;
      color: $colour;

      &:not(.read-only):hover {
        background: $colour;
        color: white;

        @if $theme == 'highlight' {
          color: $brand--neutral;
        }
      }

      &:disabled,
      &.disabled {
        background: transparent;
        border: 1px solid rgba($colour, 0.4);
        color: rgba($colour, 0.4);
      }
    }
    &__solid--#{$theme} {
      background: $colour;
      border: 1px solid $colour;
      color: white;

      @if $theme == 'brand' {
        background: linear-gradient(139deg, $startPositive 19%, $endPositive 80%);
        border: 0;
      }

      svg {
        color: white;
      }

      &:not(.read-only):hover {
        background: darken($colour, 8%);
        border-color: darken($colour, 8%);

        @if $theme == 'brand' {
          background: linear-gradient(139deg, darken($startPositive, 8%) 19%, darken($endPositive, 8%) 80%);
        }
      }

      &:disabled,
      &.disabled {
        background: rgba($colour, 0.4);
        border: 1px solid rgba($colour, 0.4);
        color: white;

        @if $theme == 'brand' {
          background: linear-gradient(139deg, rgba($startPositive, 0.4) 19%, rgba($endPositive, 0.4) 80%);
        }
      }
    }

    &__ghost--passive {
      color: $font--normal;

      &:not(.read-only):hover {
        color: $font--normal;
      }
    }
    &__icon--#{$theme} {
      background: transparent;
      border: 0;
      color: $colour;
      font-size: 16px;
      line-height: 16px;
      padding: 0;

      &:not(:last-child) {
        margin-right: 6px;
      }

      @if $theme == 'positive' {
        color: $brand--neutral;
      }

      @if $theme == 'negative' {
        color: #6a7c97;
      }

      &:not(.read-only):hover {
        color: $colour;
      }

      &:disabled,
      &.disabled {
        color: rgba($colour, 0.4);
      }
    }
    &__solid--#{$theme},
    &__ghost--#{$theme},
    &__ghost-border--#{$theme},
    &__tinted--#{$theme} {
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      font-weight: 600;
      line-height: 38px;
      padding: 0 $base * 2;
      position: relative;
      transition: 0.25s all ease;

      &:not(:last-child) {
        margin-right: $base * 2;
      }

      &.icon--left,
      &.icon--right {
        svg {
          font-size: 16px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.icon--left {
        padding-left: $base * 5;

        svg {
          left: $gutter;
        }
      }

      &.icon--right {
        padding-right: $gutter * 2;

        svg {
          right: $gutter;
        }
      }

      &.icon--condensed {
        padding-left: 16px !important;
        margin-right: 24px;
        svg {
          transform: translateY(-50%) translateX(4px);
        }
      }

      &.btn--center {
        margin: 0 auto;
      }

      &.btn--submit {
        padding: 0 $gutter * 5;
      }

      &.btn--wide {
        display: block;
        max-width: 320px;
        width: 100%;
      }

      &.btn--rounded {
        border-radius: 100px;
      }

      &.btn--tight {
        line-height: 24px;
        padding: $base/2 $base * 2;
      }

      &.btn--tight.icon--left {
        padding-left: $base * 3;

        svg {
          left: $gutter/2;
        }
      }

      &.btn--tight.icon--right {
        padding-right: $gutter * 3;

        svg {
          right: $gutter;
        }
      }

      &.btn--thin {
        padding-bottom: $base/6;
        padding-top: $base/6;
      }

      &.btn--chip {
        border: 0;
        line-height: 1;
        padding: $base/2 $base;
      }

      &.btn--chip.icon--left {
        padding-left: $base * 3;

        svg {
          left: $base/2;
        }
      }
      &.btn--chip.icon--left {
        padding-right: $base * 3;

        svg {
          right: $base/2;
        }
      }

      &.btn--small {
        font-size: 10px;
      }

      &.arrow--right {
        .arrow {
          opacity: 0;
          position: absolute;
          right: $gutter * 2;
          top: 50%;
          transform: translateX(6px) translateY(-50%);
          transition: $default-transition;

          .rect {
            transform: scaleX(0);
            transform-origin: right;
            transition: $default-transition;
            transition-delay: 0.075s;
          }
        }

        &:not(.read-only):hover .arrow {
          opacity: 1;
          transform: translateX(0) translateY(-50%);

          .rect {
            transform: scaleX(1);
          }
        }
      }

      &.btn--loading {
        color: transparent;

        .loader {
          transform: scale(0.7);

          path {
            fill: darken($colour, 8%);
          }
        }
      }
    }
  }
}

.btn__add {
  $size: 64px;

  align-items: center;
  background: $border--lightest;
  border: 1px solid $font--deep-blue;
  border-radius: $size;
  color: $brand--primary;
  display: flex;
  font-size: 31px;
  height: $size;
  justify-content: center;
  position: relative;
  width: $size;

  &:not(:last-child) {
    margin-right: $base * 2;
  }
}

.bp3-button {
  line-height: initial;
}
