@import '~styles/abstracts/abstracts';

.fancy-dropdown {
  &__content {
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow, 0 4px 18px rgba(31, 37, 50, 0.2);
    left: 50%;
    max-height: 470px;
    min-width: 140px;
    opacity: 0;
    padding: $gutter * 2 $gutter * 2 $base * 2;
    position: absolute;
    top: calc(100% + 6px);
    transform: scaleY(0) translateY(-6px) translateX(-50%);
    transform-origin: top;
    transform-style: preserve-3d;
    transition: 0.3s all $bezier;
    visibility: hidden;
    width: auto;
    z-index: 100;

    &--wide {
      width: 628px;
    }
  }

  &--left &__content {
    left: 0;
    transform: scaleY(1) translateY(-6px) translateX(0);
  }

  &--bottom {
    position: static;
  }

  &--bottom &__content {
    top: 100px;
  }

  &--tight {
    .fancy-dropdown__content {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px;
    }
  }

  &--justify {
    justify-content: center;
  }

  &__link {
    color: $font--dark;
    width: 100%;
  }

  &.is--open > &__content {
    opacity: 1;
    transform: scaleY(1) translateY(0) translateX(-50%);
    visibility: visible;
  }

  &--left.is--open > &__content {
    left: 0;
    transform: scaleY(1) translateY(0) translateX(0);
  }

  &__label {
    position: relative;
    margin-bottom: $gutter;

    &--empty,
    &:empty {
      margin-bottom: 0;
    }
  }

  &--read-only {
    cursor: text;
  }

  .suggested-contact {
    color: #f64662;
    cursor: pointer;
    font-size: 0.75rem;
  }

  &__error {
    background: $brand--negative;
    color: white;
    font-weight: 12px;
    padding: $gutter;
    text-align: center;
  }

  &__arrow {
    &::after,
    &::before {
      border: solid transparent;
      bottom: 100%;
      content: ' ';
      height: 0;
      left: 50%;
      pointer-events: none;
      position: absolute;
      width: 0;
    }

    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 4px;
      margin-left: -4px;
    }

    &::before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 5px;
      margin-left: -5px;
    }
  }

  &--left &__arrow {
    &::after,
    &::before {
      left: 12px;
    }
  }
}
