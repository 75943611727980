@import '~styles/abstracts/colours';

.input-range {
  .input-range__track {
    transition: 0s all linear;

    &--active {
      background: $brand--primary;
    }
  }

  .input-range__slider {
    background: $brand--primary;
    border-color: $brand--primary;
    transition: 0s all linear;
  }

  .input-range__slider-container {
    transition: 0s all linear;
  }

  &__markers {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}
