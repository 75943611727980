// -------------------------------------------------------------
//
//	Global styles (within react)
//	For a react-only project, use this instead of assets/sass/app.
//
//	------------------------------------------------------------

@import 'base/base';
@import '~@blueprintjs/core/src/blueprint.scss';
@import '~@blueprintjs/datetime/src/blueprint-datetime.scss';
@import 'components/buttons';
@import 'components/cards/card';
@import 'components/chip';
@import 'components/toast';
@import 'components/modals';
@import 'components/dropdowns/fancy-dropdown';
@import 'components/form/form';
@import 'components/form/validation';
@import 'components/form/inputs';
@import 'components/form/checkbox';
@import 'components/form/uploader';
@import 'components/global/PageHeader';

.header-box {
  &--search-list {
    opacity: 0.01;
    transition: opacity 500ms ease-in;

    .open & {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
  }
}

.form-stepper {
  li {
    display: block;
    float: left;
    text-align: center;
    width: 20%;

    b {
      display: block;
    }
  }
}

.editable-content-wrapper {
  width: 100%;

  .edit-icon-right {
    color: $font--light;
    display: none;
    float: right;
    width: 10%;
  }

  > a:hover {
    text-decoration: none;
  }

  &:hover .edit-icon-right {
    display: block;
  }

  [contenteditable='true']:focus {
    border-bottom: 2px solid #f37021;
    overflow: hidden;
    text-overflow: clip;
    width: 100%;
  }

  [contenteditable]:empty::before {
    /* For Firefox */
    color: $font--light;
    content: attr(placeholder);
    display: block;
    pointer-events: none;
}

  :first-child {
    float: left;
    height: 22px;
    max-width: 90%;
    width: 90%;
  }

  
}

.wrappable {
  height: unset !important;
  text-wrap: wrap !important;
}

.dp-noborder {
  .SingleDatePickerInput {
    border: none;
  }
}

.dp-small {
  .SingleDatePickerInput {
    .DateInput {
      font-size: 10px;
    }
  }
}

.dp-link {
  input {
    color: #f37021;
    cursor: pointer;
  }

  .DateInput__display-text {
    color: #f37021;
  }
}

.bp3-popover-target {
  cursor: pointer;
}

.ReactVirtualized__Grid:focus {
  outline: none;
}

.ReactVirtualized__Grid,
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible;
}
