@import '~styles/abstracts/material-icons';

input,
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
textarea {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;

  &.form__input,
  &.bp3-input {
    @extend %form__field;

    background: #fff;
    border: 1px solid $border--inactive;
    border-radius: $border-radius;
    box-shadow: none;
    color: $font--dark;
    font-weight: 100;
    height: auto;
    letter-spacing: -0.02rem;
    line-height: 22px;
    resize: none;
    transition: 0.3s all ease;
    width: 100%;

    &:focus,
    &:active {
      border-color: $font--dark;
      box-shadow: none;
      outline: none;

      & + .validation__icon {
        opacity: 0;
        visibility: hidden;
      }

      &::placeholder {
        opacity: 0.2;
      }
    }

    &::placeholder {
      color: $font--light;
      font-weight: 400;
      transition: all 0.3s ease;
    }

    .has-error &,
    .validation__has-error &,
    &.error {
      border-color: $brand--negative;
    }
  }

  input[type='password'] {
    letter-spacing: 4px;

    &:active,
    &:focus,
    & {
      &::placeholder {
        display: none;
        opacity: 0;
      }
    }
  }
}

input[type='checkbox'] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: checkbox;
}

.input--large {
  position: relative;

  &:not(:last-child) {
    margin-bottom: $big-spacer;
  }

  .add-btn {
    color: $brand--primary;
    font-size: 24px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: $base * 4;
    z-index: 1;
  }

  .validation__container {
    margin-top: $gutter * 2;
    position: relative;

    &::after {
      display: none;
    }
  }

  .validation__message {
    position: relative;
    top: 0;
  }

  .fancy-dropdown__label {
    position: static;
  }

  input,
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='text'],
  textarea {
    &.form__input,
    &.bp3-input {
      border: none;
      box-shadow: none;
      color: $font--deep-blue;
      font-size: 28px;
      font-weight: 200;
      letter-spacing: 2px;
      line-height: 1;
      padding: 0;

      &::placeholder {
        font-weight: lighter;
        opacity: 0.7;
      }

      &:focus,
      &:active {
        &::placeholder {
          opacity: 0.2;
        }
      }
    }
  }
}

textarea.bp3-input {
  padding: 1px;
}

.input--date-range {
  position: relative;

  input,
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='text'],
  textarea {
    &.form__input,
    &.bp3-input {
      color: $font--normal;
      font-size: 16px;
      font-weight: lighter;
      letter-spacing: 0;
      padding-left: 32px;
    }
  }

  &::after {
    @extend %material-icons;

    bottom: 0;
    color: $font--normal;
    content: 'date_range';
    font-size: 21px;
    left: 0;
    position: absolute;
  }
}

.form__field-wrapper {
  position: relative;

  .loader {
    right: 4px;
    left: auto;
    width: 24px;
  }
}
