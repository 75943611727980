@import '~styles/abstracts/abstracts';

.form-steps {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 120px auto;
  max-width: 375px;

  &__step {
    display: flex;
    flex: 1 0 auto;
    position: relative;
    text-align: center;
    transition: 0.3s all ease;

    &::after {
      background: $background--inactive;
      content: '';
      height: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: -1;
    }

    &--active {
      color: $brand--primary;
    }

    &--done {
      &::after {
        background: $brand--primary;
      }
    }

    &:nth-child(1) {
      text-align: left;
    }

    &:last-child {
      flex: 0 0 auto;

      &::after {
        display: none;
      }
    }
  }

  &__wrapper {
    flex: 0 0 auto;
    position: relative;
  }

  &__circle {
    $size: 40px;

    background: $background--inactive;
    border: 4px solid white;
    border-radius: 100px;
    height: $size;
    width: $size;

    .form-steps__step--active &,
    .form-steps__step--done & {
      background: $brand--primary;
    }
  }

  @keyframes animate-in-icon {
    0% {
      opacity: 0;
      transform: translate(-50%, 150%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  &__icon {
    color: white;
    font-size: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s all ease;
    // animation: 0.3s animate-in-icon ease;
  }

  &--active &__icon {
    color: white;
  }

  &__title {
    bottom: -$base * 4;
    display: inline-block;
    font-weight: 600;
    left: 50%;
    opacity: 0.5;
    position: absolute;
    transform: translateX(-50%);
    white-space: pre;

    .form-steps__step--active &,
    .form-steps__step--done & {
      color: $brand--primary;
      opacity: 1;
    }
  }
}
