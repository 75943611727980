// -------------------------------------------------------------
//
//	Loading Component
//
//	------------------------------------------------------------

.loader {
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  transition: all 0.3s ease-in-out;
}
