// -------------------------------------------------------------
//
//	SCSS Colours
//
//	------------------------------------------------------------

$brand--primary: #fb541c;
$brand--neutral: #475365;
$brand--dark: #404d57;
$brand--negative: #f64662;
$brand--secondary: #f7b733;
$brand--blue: #008af3;

$border--lightest: #f4f5f8;
$border--inactive: #dae1e6;
$border--dark: $brand--neutral;
$border--active: #aeb5c3;
$border--highlight: #cce9ff;

$background: #f7f8f9;
$background--active: #f7fafc;
$background--inactive: #dae1e6;
$background--highlight: #f5fbff;

$font--dark: #475365;
$font--deep-blue: #6a7c97;
$font--normal: #7a7c84;
$font--light: #aeb5c3;
$font--lighter: #dae1e6;
