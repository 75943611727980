@import '~styles/abstracts/material-icons';

.validation {
  $tooltip-size: 6px;

  &__container {
    &::after {
      bottom: 8px;
      color: $brand--negative;
      content: 'error';
      font-size: 16px;
      position: absolute;
      right: 8px;
      transform: translateY(2px);
      transition: 0.3s all $bezier;

      @extend %material-icons;

      .form__row--has-focus & {
        opacity: 0;
        transform: translateY(0);
      }
    }
  }

  &__has-error {
    .form__input,
    .form__input:focus,
    .form__input:active {
      border-color: $brand--negative;
    }

    .validation__icon {
      color: $brand--negative;
      opacity: 1;
      visibility: visible;
    }
  }

  &__icon {
    height: auto;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s $bezier;
    transition-delay: 0.2s;
    visibility: hidden;
    width: 16px;
  }

  &__message {
    background: $brand--negative;
    border: 1px solid $brand--negative;
    border-radius: $border-radius;
    color: white;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    left: 0;
    line-height: 18px;
    max-width: 240px;
    min-width: none;
    opacity: 0;
    padding: $base/2 $base;
    position: absolute;
    top: calc(100% + 8px);
    transform: translateY(8px);
    transition: $default-transition;
    visibility: hidden;
    width: auto;
    z-index: 10;

    &::after {
      @include triangle('up', $tooltip-size, $brand--negative);

      left: 16px;
      top: -$tooltip-size;
    }

    &--show,
    &--error {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.message {
  &--error {
    background: lighten($brand--negative, 35);
    border: 1px solid $brand--negative;
    border-radius: 5px;
    color: $brand--negative;
    margin: 0 15px;
    padding: 5px;
    text-align: center;

    .fancy-dropdown__content & {
      margin: 0 33px;
    }
  }
}
