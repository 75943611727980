@import './colours';
@import './variables';
@import './typography';

$pt-intent-primary: $brand--blue;
$pt-intent-warning: $brand--primary;
$pt-intent-danger: $brand--negative;

$pt-font-family: $font-copy;
$pt-icon-size-standard: 16px;

$pt-border-radius: $border-radius;

$pt-border-shadow-opacity: 0.14;
$pt-drop-shadow-opacity: 0.14;

$pt-transition-ease: $bezier;
$pt-transition-duration: 0.1s;

$pt-z-index-base: 0;
$pt-z-index-content: $pt-z-index-base + 2000;
$pt-z-index-overlay: $pt-z-index-content + 9000;

$dark-gray1: scale-color($brand--neutral, $lightness: -30%);
$dark-gray2: scale-color($brand--neutral, $lightness: -20%);
$dark-gray3: scale-color($brand--neutral, $lightness: -10%);
$dark-gray4: $brand--neutral;
$dark-gray5: scale-color($brand--neutral, $lightness: 10%);

$gray1: scale-color($brand--neutral, $lightness: 20%);
$gray2: scale-color($brand--neutral, $lightness: 30%);
$gray3: scale-color($brand--neutral, $lightness: 40%);
$gray4: scale-color($brand--neutral, $lightness: 50%);
$gray5: scale-color($brand--neutral, $lightness: 60%);

$light-gray1: scale-color($brand--neutral, $lightness: 70%);
$light-gray2: scale-color($brand--neutral, $lightness: 80%);
$light-gray3: scale-color($brand--neutral, $lightness: 90%);
$light-gray4: scale-color($brand--neutral, $lightness: 100%);
$light-gray5: #f7f8f9;
