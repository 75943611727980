@import '~styles/abstracts/abstracts';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $font--dark;
  font-weight: lighter;

  .bp3-tooltip & {
    color: $font--lighter;
  }
}

h1,
.h1 {
  font-size: 42px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 30px;
}

h4,
.h4 {
  font-size: 24px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

.large-text {
  @extend %large-text;
}

p {
  font-size: 14px;
  font-weight: lighter;

  &.fc--light {
    color: $font--light;
  }
}

.fc--light {
  color: $font--light;
}

// Links
.link {
  @extend %link;

  &--dark {
    @extend %link--dark;
  }
}

.label {
  display: block;
  font-size: 10px;
  text-transform: uppercase;

  @extend %form__label;

  &__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &--highlight {
    color: $brand--primary;
    font-size: 12px;
    font-weight: bold;
  }
}

.bp3-menu-item > svg {
  margin-top: 2px;
}

.bp3-tooltip {
  h6 {
    color: rgba(#fff, 0.7);
    font-weight: 200;
    font-size: 14px;
    margin-bottom: $gutter/2;
  }
  ul {
    list-style: disc inside;
    padding: 0;
    margin: 0;
    li:before {
      content: '';
      margin-left: -6px;
    }
  }
  :last-child {
    margin-bottom: 0;
  }
}
