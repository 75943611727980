// Spacing
$base: 8px;
$gutter: 12px;
$spacer: 40px;
$big-spacer: 60px;
$container-width: 1064px;
$nav-height: 64px;

// Padding
$big-gutter: $gutter * 2;

// Animation
$default-transition: 0.2s all ease;
$border-radius: 5px;
$bezier: cubic-bezier(0.23, 1, 0.32, 1);

$box-shadow: 0 8px 16px -8px rgba(42, 83, 115, 0.14);
$box-shadow--on-white: $box-shadow, 0 4px 18px rgba(31, 37, 50, 0.2);
